import React from 'react';

const Thoughts = () => {
  return (
    <div className="thoughts">


      <section>
        <h2>other thoughts</h2>
        <ul>
          in progress
        </ul>
      </section>
    </div>
  );
};

export default Thoughts;

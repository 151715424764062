import React from 'react';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Projects from './components/Projects';
import About from './components/About';
import Journal from './components/Thoughts'; // Correctly imported as Journal
import './App.css';

const App = () => {
  return (
    <Router>
      <div>
        <nav>
          <Link to="/">Projects</Link>
          <Link to="/about">About</Link>
          <Link to="/thoughts">Other thoughts</Link>
        </nav>
        <Routes>
          <Route path="/" element={<Projects />} />
          <Route path="/about" element={<About />} />
          <Route path="/thoughts" element={<Journal />} /> {/* Fixed here */}
        </Routes>
      </div>
    </Router>
  );
};

export default App;

import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h1>About Me</h1>
      <p>
      I am a Product Manager at Microsoft working on Copilot extensibility and previously worked as a PM in Azure Marketplace - Microsoft's B2B software marketplace. 
      
      I am passionate about building and will be sharing my work here <a href="https://portfolio.aguadulce.tech/">here.</a> 
      
      Find me at: </p>
      <a href="https://github.com/manknewl" className="about-link">GitHub</a>
      <a href="https://linkedin.com/in/mcerrutti" className="about-link">LinkedIn</a>
      <a href="https://x.com/manknewl" className="about-link">Twitter</a>
    </div>
  );
};

export default About;

import React from 'react';
import Modal from 'react-modal';

const ProjectModal = ({ project, isOpen, onRequestClose }) => {
  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={project.title}
      className="modal"
      overlayClassName="overlay"
    >
      <button className="close-button" onClick={onRequestClose}>
        Close
      </button>
      <div className="modal-content">
        <h1>{project.title}</h1>
        <p><strong>Summary:</strong> {project.summary}</p>
        {project.description && <p><strong>Description:</strong> {project.description}</p>}
        {project.link && (
          <p>
            <strong>Project Link:</strong>{' '}
            <a href={project.link} target="_blank" rel="noopener noreferrer">
              {project.link}
            </a>
          </p>
        )}
      </div>
    </Modal>
  );
};

export default ProjectModal;

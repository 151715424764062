import React, { useState } from 'react';
import ProjectCard from './ProjectCard';
import ProjectModal from './ProjectModal';

const Projects = () => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const projectsByYear = [
    {
      year: '2024',
      projects: [
        {
          id: 1,
          title: 'Toronto House History',
          summary: 'A Flask web app to help you find who lived in your Toronto house. It uses the OpenAI API to narrate what previous owners did and makes a summary of your house.',
          link: 'https://toronto.aguadulce.tech',
          description: 'The University of Toronto digitized the Historical City directories but it takes a few hours to go through the guides to learn who lived in your house (I did it). This project helps you get this in < 1 min and also writes an AI-generated summary and a 1st person narration of the people who lived in your house.',
          image: '/images/houses.jpg',
          skills: 'OpenAI API, Flask web app, unstructured data preparation and extraction using python',
        },
        {
          id: 2,
          title: 'Movie Finder',
          summary: 'Find what’s playing in Toronto theatres.',
          link: 'https://movies.aguadulce.tech',
          description: 'An app to browse and discover movies currently playing in Toronto. It includes showtimes, trailers, and reviews to help users plan their movie night.',
          image: '/images/tiff.jpg',
          skills: 'web scraping using selenium and beautiful soup, vector embedding and databases, ux design',
        },
        {
          id: 3,
          title: 'ASL Translator',
          summary: 'An app to translate text or meeting transcripts into American Sign Language using OpenAI and custom-built video processing pipelines.',
          link: 'https://asl.aguadulce.tech',
          description: 'This project helps bridge communication barriers by translating text into ASL gloss and generating video clips of the signs. It uses Microsoft Graph for transcript retrieval and OpenAI for ASL gloss generation.',
          image: '/images/asl.jpg',
          skills: 'ASL basics, image extraction from youtube, image processing/cleaning, MS graphAPI to grab meeting transcript, OpenAI API',
        },
        {
          id: 4,
          title: 'Real Estate Toronto App',
          summary: 'A real estate app focused on Toronto with a modern design.',
          link: 'https://realtor.aguadulce.tech',
          description: 'Developed with the first generation of Microsoft Form Recognizer in 2019, this app simplifies real estate search in Toronto by using form recognizer to extract property details such as neighbourhood, price etc. and organizes to an excel file. It is currently being redeveloped using GPT for enhanced functionality and design.',
          image: '/images/realestate.jpg',
          skills: 'logic apps, image recognizer api, APIs (google maps, walkscore, bing)',
        },
        {
          id: 5,
          title: 'CN Tower Telescope',
          summary: 'A real-time view of the CN Tower from my telescope.',
          link: 'https://65acc0f78bd305a90ec9144695e83a64.serveo.net/control',
          description: 'A project that streams real-time images of the CN Tower captured from a telescope connected to a Raspberry Pi. It includes a control interface for adjusting the telescope remotely.',
          image: '/images/cn.jpg',
          skills: 'rpi, flask/web app, ngrok',
        },
        {
          id: 6,
          title: 'RC Internet Control Car',
          summary: 'Converted an off-the-shelf RC car to be controlled over the internet using an RPi Zero.',
          link: 'https://car.aguadulce.tech',
          description: 'Using a Raspberry Pi Zero and motor controller, I upgraded a standard RC car for internet-based control. It features real-time camera streaming and remote operation for exploration or fun.',
          image: '/images/rccar.jpg',
          skills: 'RaspberryPi, motor controller wiring, flask app to control rc remotely',
        },
        {
          id: 7,
          title: 'Self-Driving Leaf Picker with Mechanical Arm and Vision',
          summary: 'A robotic project inspired by Nat Friedman’s tweet to autonomously pick up leaves.',
          description: 'This project uses multiple cameras, AI-based leaf recognition, and a mechanical arm to pick up leaves and place them in user-defined piles. Designed for autonomous outdoor cleanup, it demonstrates innovative robotics and vision-based automation.',
          image: '/images/natfriedman.jpg',
          skills: 'rpi5, servo, dc, image recognition using and model training using roboflow API',
        },
        {
          id: 8,
          title: 'WhatsApp App for Smart Historical Walks',
          summary: 'A location-based historical walking guide app powered by WhatsApp.',
          description: 'Extracted data from books to create geoJSON datasets for Toronto walking guides. This app sends users location-specific historical and cultural facts as voice messages through WhatsApp, enhancing the walking experience.',
          image: '/images/whatsapp.jpg',
          skills: 'whatsapp pro API, Azure communication services, SQL database',
        },
        {
          id: 9,
          title: 'Portfolio Website',
          summary: 'Learnt to use React template to make a more responsive website.',
          image: '/images/portfolio.jpg',
          skills: 'git, static web apps'
        },
      ],
    },
  ];

  const handleProjectClick = (project) => {
    setSelectedProject(project);
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setSelectedProject(null);
    setIsModalOpen(false);
  };

  return (
    <div className="projects-container">
      {projectsByYear.map((yearGroup) => (
        <div key={yearGroup.year} className="year-group">
          <h2>{yearGroup.year}</h2>
          <div className="projects">
            {yearGroup.projects.map((project) => (
              <ProjectCard
                key={project.id}
                project={project}
                onClick={() => handleProjectClick(project)}
              />
            ))}
          </div>
        </div>
      ))}
      {selectedProject && (
        <ProjectModal
          project={selectedProject}
          isOpen={isModalOpen}
          onRequestClose={handleCloseModal}
        />
      )}
    </div>
  );
};

export default Projects;
